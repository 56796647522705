import dsAppBG from "../assets/appBGImage/dsAppBG.png";
import dsAppBG_dark from "../assets/appBGImage/dsAppBG_dark.png";

export const styles = {
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  menu: {
    light: {
      backgroundColor: "#203837",
    },
    dark: {
      backgroundColor: "#2a2d41",
    },
  },

  appHeader: {
    light: {
      backgroundColor: "white",
    },
    dark: {
      backgroundColor: "#d4d4d4",
    },
  },
  appBG: {
    light: {
      backgroundColor: "#ebeef0",
      backgroundImage: `url(${dsAppBG})`,
      color: "#666",
    },
    dark: {
      backgroundColor: "#0d1117",
      backgroundImage: `url(${dsAppBG_dark})`,
      color: "#fff",
    },
  },
  accordion: {
    light: {
      color: "primary",
    },
    dark: {
      color: "#fff",
    },
  },
};
