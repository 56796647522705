import React, { useState } from "react";
import "./header.scss";
import logo from "../../../assets/biorad_logo.svg";
import accountuser from "../../../assets/accountuser.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Stack from "@mui/material/Stack";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";

export default function Header({ showContent }) {
  const { theme, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //const pageTitleStyle = { 'color': '#00a650', 'font-size': '21px',"font-weight": '600', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }
  //const subpageTitleStyle = { 'color': '#00a650', 'font-style':'italic','font-size': '21px',"font-weight": '300', "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }
  const handleClick = (event) => {
    if (open === false) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    // authentication.signOut(instance)
  };

  return (
    <Box
      className="header"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "7%",
        backgroundColor: theme === "light" ? styles.appHeader.light.backgroundColor : styles.appHeader.dark.backgroundColor,
        borderBottom: "0.25em solid #00a650",
        display: "flex",
        alignItems: "center",
        placeContent: "space-between",
        zIndex: 9999,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", placeContent: "start", ml: 4 }}>
        <img className="logo" sx={{ height: "25px" }} src={logo} alt="logo" />

        <Typography className="title" sx={{ color: "#00a650", fontSize: "22px", ml: 3, mr: 2 }}>
          Image Lab Advanced [feasibility]
        </Typography>
        <Box className="dottedMark" sx={{ pb: "10px", fontWeight: "bold", color: "#00a650" }}>
          .
        </Box>

        <Typography className="subtitle" sx={{ ml: 2, pt: 0.3, color: "#00a650", fontSize: "18px", fontWeight: "bold" }}>
          {`${showContent === "analytics" ? "Analytics" : showContent === "architecture" ? "Personalized Analytics Architecture" : "Cloud Upload"}`}
        </Typography>
      </Box>
      <React.Fragment>
        <Stack direction="row" sx={{ mr: 4 }}>
          <Box>
            <IconButton sx={{ ml: 1 }} onClick={toggleTheme}>
              {theme === "light" ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>
          <Box sx={{ alignItems: "center" }}>
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
              <img src={accountuser} height="25" alt="logo" />
            </IconButton>
          </Box>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{
            "& .MuiPaper-root": {
              elevation: 0,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <label className="title">{sessionStorage.getItem("username")}</label>
          </MenuItem>

          <Divider />
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    </Box>
  );
}
