import React, { useState, useEffect } from "react";
import "./menu.scss";
import analytics from "../../../assets/analytics-not-selected.svg";
import upload from "../../../assets/new-upload-not-selected.svg";
import analyticsSelected from "../../../assets/analytics-selected.svg";
import architectureSelected from "../../../assets/architecture-selected.svg";
import architecture from "../../../assets/architecture-not-selected.svg";
import uploadSelected from "../../../assets/new-upload-selected.svg";
import expand from "../../../assets/expand.svg";
import collapse from "../../../assets/collapse.svg";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../theme/ThemeContext";
import { styles } from "../../../theme/ThemeStyle";
import Box from "@mui/material/Box";

function MainMenu({ callback, menuState, activeMenu }) {
  const { theme } = useTheme();
  const [activeClass, setActiveClass] = useState("home");
  const [expandable, setExpandable] = useState(false);

  const menuTitleStyle = {
    color: "#a9a8a8",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50px",
    padding: "10px 0 4px 0",
    borderBottom: "solid 2px transparent",
    mt: "10px",
    height: "32px",
    mb: "4px",
    ml: "1px",
    placeContent: "center",
    fontFamily: styles.fontFamily,
  };
  const menuActiveTextStyle = {
    mt: "4px",
    pl: "10px",
    width: "65px",
    display: "none",
    textAlign: "left",
    fontSize: "15px",
  };

  let navigate = useNavigate();
  //let history = useHistory();
  useEffect(() => {}, [activeClass]);

  let setMenu = (value) => {
    sessionStorage.setItem("selectedMenu", value);
    activeMenu = value;
    setActiveClass(value);
    if (callback) {
      callback();
    }
    switch (value) {
      default:
        navigate("/home");
        break;
      case "home":
        navigate("/" + value);
        break;
      case "analytics":
        navigate("/" + value);
        break;
      case "architecture":
        navigate("/" + value);
        break;
    }
  };

  let setToggle = () => {
    let v = !expandable;
    setExpandable(v);
    sessionStorage.setItem("menuState", v);
    if (menuState) {
      menuState();
    }
  };

  return (
    <Box
      className="main-menu"
      sx={{
        backgroundColor: theme === "light" ? styles.menu.light.backgroundColor : styles.menu.dark.backgroundColor,
        position: "fixed",
        left: 0,
        top: "7%",
        height: "100%",
        width: "4em",
        borderRight: "solid 1px #d2d4d6",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box className={`iconLabel ${activeClass === "home" && activeMenu === "home" ? "active" : ""}`} sx={menuTitleStyle} onClick={() => setMenu("home")}>
        <img className="normal" src={upload} alt="" />
        <img className="selected" src={uploadSelected} alt="" />
        <Box className="label" id="homeID" sx={menuActiveTextStyle}>
          {" "}
          Upload{" "}
        </Box>
      </Box>
      <Box className={`iconLabel ${activeClass === "analytics" && activeMenu === "analytics" ? "active" : ""}`} sx={menuTitleStyle} onClick={() => setMenu("analytics")}>
        <img className="normal" src={analytics} alt="" />
        <img className="selected" src={analyticsSelected} alt="" />
        <Box className="label" id="homeID" sx={menuActiveTextStyle}>
          {" "}
          Analytics{" "}
        </Box>
      </Box>
      <Box className={`iconLabel ${activeClass === "architecture" && activeMenu === "architecture" ? "active" : ""}`} sx={menuTitleStyle} onClick={() => setMenu("architecture")}>
        <img className="normal" src={architecture} alt="" />
        <img className="selected" src={architectureSelected} alt="" />
        <Box className="label" id="homeID" sx={menuActiveTextStyle}>
          {" "}
          Personal Architecture{" "}
        </Box>
      </Box>
      <Box className={`iconLabel toggle ${expandable ? "active" : ""}`} sx={menuTitleStyle} onClick={setToggle}>
        <img className="normal" src={expand} alt="" />
        <img className="selected" src={collapse} alt="" />
        <Box className="label" id="expandIconID" sx={menuActiveTextStyle}></Box>
      </Box>
    </Box>
  );
}

export default MainMenu;
